import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import './assets/css/ug.css'
import router from './router'
import isMobile from './isMobile/index.js'
import wx from "weixin-js-sdk";
Vue.prototype.$wx = wx;
Vue.prototype.$url = "https://pay.mulazim.com/ug/v1/sms-pay/";//正式
// Vue.prototype.$url = "https://swoole-api.almas.biz/ug/v1/sms-pay/";//测试
// Vue.prototype.$url = "https://smart-rz.d.almas.biz/ug/v1/sms-pay/";//测试
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.prototype.$isMobile = isMobile;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
