function isMobile() {undefined
    let flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
}

function isWX() {
  let ua = navigator.userAgent.toLowerCase()
  return (/micromessenger/.test(ua))
}
export default {
  isMobile,
  isWX
}