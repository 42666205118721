<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
};
</script>
<style>
* {
  font-family: "UKIJEkran";
}
.loading {
  width: 100%;
  height: 100vh;
  background: #0000009c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1111;
}
.loading img {
  width: 70px;
}
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.loading img {
  animation: spinning 1s linear infinite;
}
</style>
