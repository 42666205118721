<template>
  <div class="msg">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
.msg {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000009c;
}
h1 {
  padding: 35px 20px;
  text-align: center;
  color: #373737;
  background: #fff;
  margin: 0px 25px;
  border-radius: 10px;
  font-size: 14px;
}

</style>
