<template>
  <div id="home">
    <div class="loading" v-if="loading">
      <img src="../assets/img/loading.png" />
    </div>
    <HelloWorld :msg="msg" v-if="loading == false && msg != ''"></HelloWorld>
    <!-- 顶部导航 -->
    <div class="header">Mulazim</div>
    <div class="container">
      <!-- 订单信息 -->
      <div class="order_information">
        <div class="order_item">
          <div class="order_list">
            <div>نومۇرى :</div>
            <div>订单号 :</div>
          </div>
          <div class="order_number">{{ order_id }}</div>
        </div>
        <div class="order_item">
          <div class="order_list">
            <div>زاكاز پۇلى :</div>
            <div>订单金额 :</div>
          </div>
          <div class="order_number">
            ￥{{ price + shipment + lunch_box_fee }}
          </div>
        </div>
        <div class="order_item">
          <div class="order_list">
            <div>زاكاز ھالىتى :</div>
            <div>订单状态 :</div>
          </div>
          <div class="order_number" style="color: #ff2a2a">
            <div>{{ state.ug }}</div>
            <div>{{ state.zh }}</div>
          </div>
        </div>
        <div class="order_item">
          <div class="order_list">
            <div>ئادرېسى :</div>
            <div>收货地址 :</div>
          </div>
          <div class="order_number" style="font-size: 13px;">
            <div>{{ building_name_zh }} ({{ building_name_ug }})</div>
            <div>{{ order_address }}</div>
          </div>
        </div>
        <div class="order_item">
          <div class="order_list">
            <div>يەتكۈزگۈچى :</div>
            <div>骑手电话 :</div>
          </div>
          <div class="order_number">
            <a style="color: #139d59" :href="'tel:' + admin_phone">{{ admin_phone }}</a>
          </div>
        </div>
      </div>

      <div class="order_detail_s">
        <div class="order_items">
          <div class="order_lists">
            <div>تاماق پۇلى</div>
            <div>餐费</div>
          </div>
          <div class="order_numbers">￥{{ price }}</div>
        </div>

        <div class="order_items">
          <div class="order_lists">
            <div>قاچا پۇلى</div>
            <div>饭盒费</div>
          </div>
          <div class="order_numbers">￥{{ lunch_box_fee }}</div>
        </div>

        <div class="order_items">
          <div class="order_lists">
            <div>كىرا پۇلى</div>
            <div>配送费</div>
          </div>
          <div class="order_numbers">￥{{ shipment }}</div>
        </div>

        <div class="order_items">
          <div class="order_lists">
            <div>جەمئىي پۇلى</div>
            <div>总金额</div>
          </div>
          <div class="order_numbers">
            ￥{{ price + shipment + lunch_box_fee }}
          </div>
        </div>
      </div>
      <!-- 付款按钮 -->
      <div class="pay_buttom">
        <div class="pay WeChat_paid" @click="handlerBrowser(1, pay_platform_label)" v-if="filter == false &&
          cash_clear_state == 0 &&
          loading == false &&
          status < 8
          ">
          <div>پۇل تۆلەي</div>
          <div>立即支付</div>
        </div>
        <div class="pay WeChat_pay" v-if="cash_clear_state == 1 && loading == false && status < 8">
          <div>پۇل تۆلەپ بولغان</div>
          <div>已支付</div>
        </div>
        <div class="pay WeChat_paid" @click="handlerWeixin(2, pay_platform_label)" v-if="filter == true &&
          cash_clear_state == 0 &&
          loading == false &&
          status < 8
          ">
          <div>پۇل تۆلەي</div>
          <div>立即支付</div>
        </div>
      </div>
      <iframe id="myIframe" width="0px" height="0px"></iframe>
      <!-- 订单详情 -->
      <div class="order_detail">
        <div class="order_title">
          <div>تاماق تەپسىلاتى</div>
          <div>订单详情</div>
        </div>
        <div class="order_detail_items" v-for="(item, index) in foods" :key="index">
          <div class="title">
            <div class="name">{{ item.name_zh }}{{ item.name_ug }}</div>
            <div>x{{ item.number }}</div>
          </div>
          <div class="price">￥{{ item.price }}</div>
        </div>
      </div>
    </div>
    <!-- 底部导航 -->
    <div class="footer">Mulazim外卖</div>
  </div>
</template>

<script>
import HelloWorld from "../components/HelloWorld.vue";
export default {
  name: "home",
  created() {
    this.filter = this.$isMobile.isWX();
    let orderId = this.$route.query.rid;
    if (orderId == undefined) {
      this.$router.push("/mulazim");
      return;
    }
    this.order(this.$isMobile.isWX());
    setTimeout(() => {
      this.msg = "";
    }, 3000);
    localStorage.setItem("order_id", this.$route.query.rid);
  },
  data() {
    return {
      building_name_ug: "",
      building_name_zh: "",
      admin_phone: "",
      order_address: "",
      order_id: "",
      price: "",
      foods: [],
      lunch_box_fee: "",
      shipment: "",
      loading: true,
      msg: "",
      filter: "",
      cash_clear_state: "",
      state: {},
      status: 0,
      pay_platform_label: 'default',
      url_scheme: ''
    };
  },
  methods: {
    getLogin() {
      // let redirect_uri = "https://pay.almas.biz/login";//测试
      // let appid = "wx1cf945e84256c294"; //测试
      let redirect_uri = "https://pay.mulazim.com/login";//正式
      let appid = "wxa7cc6c5e6ca7ca16";//正式
      redirect_uri = encodeURIComponent(redirect_uri);
      var url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      window.location.href = url;
    },
    //微信转账
    async handlerWeixin(type, pay_platform_label) {
      let that = this;
      let userInfo = JSON.parse(localStorage.getItem("setLogin"))
      if (pay_platform_label == 'lakala') {
        location.href = that.url_scheme;
        return
      }
      if (userInfo == null && that.filter == true) {
        that.getLogin();
        return;
      }
      //获取url参数
      let orderId = that.$route.query.rid;
      this.$axios({
        method: "post",
        url: that.$url + "pay",
        data: { type: type, rid: orderId, openid: userInfo.openid },
      }).then((res) => {
        if (res.data.status != 200) {
          that.msg = res.data.msg;
          setTimeout(() => {
            this.msg = "";
          }, 3000);
          return;
        }
        let data = res.data.data;
        that.$wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.paySign, // 必填，签名，见附录1
          jsApiList: ["chooseWXPay"],
          // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        that.$wx.ready(function () {
          that.$wx.chooseWXPay({
            appId: data.appId,
            timestamp: data.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: data.paySign, // 支付签名
            success: function () {
              that.$router.push("/result");
            },
            cancel: function () {
              that.msg = "پۇل تۆلەش مەغلۇپ بولدى";
              setTimeout(() => {
                that.msg = "";
              }, 1500);
            },
          });
        });
      });
    },
    //获取订单信息
    async order(weixin) {
      //获取url参数
      let that = this;
      let orderId = that.$route.query.rid;
      this.$axios
        .get(that.$url + "order-detail", {
          params: {
            rid: orderId,
            pay_from: weixin ? 1 : 2
          },
        })
        .then(function (res) {
          console.log(res);
          that.loading = false;
          if (res.data.status != 200) {
            that.$router.push("/error");
            return;
          }
          let datas = res.data.data;
          that.admin_phone = datas.admin_phone;
          that.pay_platform_label = datas.pay_platform_label || 'default';
          that.url_scheme = datas.url_scheme;
          that.building_name_ug = datas.building_name_ug;
          that.building_name_zh = datas.building_name_zh;
          that.order_address = datas.order_address;
          that.order_id = datas.order_id;
          that.price = datas.price;
          that.foods = datas.foods;
          that.lunch_box_fee = datas.lunch_box_fee;
          that.cash_clear_state = datas.cash_clear_state;
          that.shipment = datas.shipment;
          that.status = datas.state;
          localStorage.setItem("mulazimInfo", JSON.stringify(datas));
          let status = [
            { ug: "پۇل تۆلەشنى ساقلاۋاتىدۇ", zh: "等待支付" },
            { ug: "زاكاز مۇقىملاندى ", zh: "订单已确认" },
            { ug: "زاكاز قوبۇللىنىۋاتىدۇ", zh: "等待接单" },
            { ug: "زاكاز قوبۇل قىلىندى", zh: "已接单" },
            { ug: "زاكاز تەييار بولدى", zh: "订单已准备" },
            { ug: "زاكاز يەتكۈزىلىۋاتىدۇ", zh: "订单配送中" },
            { ug: "زاكاز يەتكۈزۈلۈپ بولدى", zh: "配送完毕" },
            { ug: "زاكاز بىكار قىلىندى", zh: "订单已取消" },
            { ug: "زاكاز قوبۇل قىلىنمىدى ", zh: "餐厅拒绝接单" },
            { ug: "زاكاز يەتكۈزۈش مەغلۇب بولدى ", zh: "订单配送失败" },
          ];
          that.state = status[datas.state - 1];
          let userInfo = JSON.parse(localStorage.getItem("setLogin"))
          if (datas.pay_platform_label != 'lakala') {
            if (userInfo == null && that.filter == true) {
              that.getLogin();
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //浏览器支付
    async handlerBrowser(type, pay_platform_label) {
      let that = this;
      if (pay_platform_label == 'lakala') {
        location.href = that.url_scheme;
        return
      }
      let orderId = this.$route.query.rid;
      // 发起一个post请求
      this.$axios({
        method: "post",
        url: that.$url + "pay",
        data: { type: type, rid: orderId },
      })
        .then((res) => {
          console.log(res);
          if (res.data.status != 200) {
            that.msg = res.data.msg;
            setTimeout(() => {
              this.msg = "";
            }, 3000);
            return;
          }
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          const isEdge = /(Edge\/)|(Edg\/)|(EdgA\/)/.test(navigator.userAgent);
          if (isIOS) {
            // 用户使用iOS Safari浏览器
            window.location.href = res.data.data.mweb_url;
          } else {
            if (isEdge) {
              window.location.href = res.data.data.mweb_url;
            } else {
              // 用户使用其他浏览器
              var iframe = document.getElementById("myIframe");
              iframe.setAttribute("src", res.data.data.mweb_url);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    HelloWorld,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "UKIJEkran";
  background-color: #f1f1f1;
}

.header {
  width: 100%;
  height: 50px;
  background-color: #139d59;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
}

.container {
  width: 100%;
}

.order_information {
  width: 96%;
  background-color: #fff;
  border-radius: 6px;
  margin: 10px auto;
}

.order_item {
  padding: 6px 5px 6px 0px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}

.order_list {
  flex: 1.2;
  text-align: right;
  color: #333333;
  font-size: 16px;
}

.order_number {
  padding: 0 6px;
  flex: 3;
  color: #373737;
  font-size: 15px;
  word-break: break-all;
}

.pay_buttom {
  width: 100%;
}

.pay {
  width: 90%;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  margin: auto;
}

.WeChat_pay {
  background-color: #139d59;
}

.WeChat_paid {
  background-color: #e85e5e;
}

.footer {
  padding: 12px 5px;
  font-size: 16px;
  text-align: center;
  color: #959595;
}

.order_detail {
  background-color: #fff;
  border-radius: 6px;
  width: 96%;
  margin: 0px auto 10px;
  padding: 0px 0 15px;
}

.order_title {
  padding: 5px 0;
  font-size: 15px;
  color: #139d59;
  text-align: center;
}

.order_detail_items {
  padding: 5px 5px;
  display: flex;
  margin: 0 5px;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}

.order_detail_items div {
  padding: 2px 0px;
}

.order_detail_items .price {
  color: red;
  font-size: 17px;
  text-align: right;
  width: 15%;
}

.order_detail_items .title {
  width: 85%;
}

.order_detail_items .title .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
}

.order_detail_s {
  width: 96%;
  margin: 10px auto;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  padding: 10px 0;
}

.order_detail_s .order_items {
  width: 50%;
  text-align: center;
}

.order_detail_s .order_lists {
  color: #333333;
  font-size: 14px;
}

.order_detail_s .order_numbers {
  color: #e85e5e;
  font-size: 18px;
  font-weight: 400;
  margin-top: 3px;
}

#myIframe {
  opacity: 0;
}
</style>

